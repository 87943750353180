import React, { useEffect } from "react";
import { DCChainViewer } from "@duncrow/chain-component-library";
import { FormattedMessage } from "react-intl";

import { ReactComponent as MouseLeft } from "./icons/mouse-left.svg";
import { ReactComponent as MouseRight } from "./icons/mouse-right.svg";
import { ReactComponent as MouseWheel } from "./icons/mouse-wheel.svg";
import { ReactComponent as Reset } from "./icons/arrow-counterclockwise.svg";

import "babylonjs";
import "babylonjs-loaders";
import "babylonjs-gui";

function ThreeDimensional({ product, apikey, backend }) {
  const assetsFolder = "./productViewer/";
  const settings = {
    csvRootPath: assetsFolder,
    csvPrefix: "parts-",
    assetsRootPath: assetsFolder,
    partListPath: assetsFolder + "partlist.json",
    environmentFile: "kettenwaelder_HDRI.env",
    environmentRootPath: assetsFolder,
    loading3DFile: "empty.glb",
    screenShotSize: { width: 2400, height: 2400 },
  };

  let viewer;

  function setViewer(data) {
    if (data.viewer) {
      viewer = data.viewer;

      if (product.data.image == "default.png") {
        setTimeout(() => {
          viewer.createScreenshot().then((image) => {
            console.log(`Screenshot created for ${product.data.cromox_code}.`);

            let api_url = backend + "/cromox/v1/upload";

            let xhr = new XMLHttpRequest();

            let data = {
              code: product.data.cromox_code,
              image: image,
            };

            xhr.open("POST", api_url, true);
            xhr.setRequestHeader(
              "Content-type",
              "application/json; charset=UTF-8"
            );
            xhr.setRequestHeader("X-API-Key", apikey);

            // set function to handle response
            xhr.onload = function () {
              if (xhr.status >= 200 && xhr.status < 300) {
                console.log("Response:", xhr.responseText);
              } else {
                console.error("Error:", xhr.statusText);
              }
            };

            // set function to handle errors
            xhr.onerror = function () {
              console.error("Request failed");
            };

            // stringify
            xhr.send(JSON.stringify(data));
          });
        }, 500);
      }
    }
  }

  function resetCamera() {
    if (viewer) {
      console.log("Reset camera of the viewer component.");
      viewer.resetCamera();
    }
  }

  return (
    <div id="stage-3d">
      <div className="legend d-flex flex-wrap xjustify-content-center mb-3">
        <div className="me-3 mb-2">
          <MouseLeft className="me-1 mb-1" />
          <FormattedMessage
            id="display.rotate"
            defaultMessage="Rotate"
            description="Label for rotate"
          />
        </div>
        <div className="me-3 mb-2">
          <MouseRight className="me-1 mb-1" />
          <FormattedMessage
            id="display.move"
            defaultMessage="Move"
            description="Label for move"
          />
        </div>
        <div className="me-3 mb-2">
          <MouseWheel className="me-1 mb-1" />
          <FormattedMessage
            id="display.zoom"
            defaultMessage="Zoom"
            description="Label for zoom"
          />
        </div>
        <div className="me-3 mb-2">
          <a
            href="#target"
            onClick={(e) => {
              resetCamera();
              e.preventDefault();
            }}
            className="text-decoration-none"
          >
            <Reset className="me-1 mb-1" />
            <FormattedMessage
              id="display.reset"
              defaultMessage="Reset"
              description="Label for reset"
            />
          </a>
        </div>
      </div>
      <div id="canvas-container">
        <DCChainViewer
          initSettings={settings}
          chainName={product.data.code}
          chainLenght={product.data.length}
          onGetViewer={setViewer}
        />
      </div>
    </div>
  );
}

export default ThreeDimensional;
